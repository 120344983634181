<template>
  <!-- ad list -->
  <div
    :class="{ 'minimal': minimal }"
    class="ads white"
  >
    <div class="ad-nav px-6 d-flex align-center">
      <v-menu
        v-model="menu.new"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :disabled="!editable"
            color="accent"
            class="btn-new flex-shrink-0 mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ icons.add }}
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            class="btn-new-option new"
            @click="create(null)"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>{{ icons.new }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-overline">Novo {{ getDictionary('ad') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-menu
            v-if="copyableList.length>0"
            v-model="menu.duplicate"
            right
            offset-x
            open-on-hover
            close-on-click
            close-delay="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                class="btn-new-option duplicate"
                v-bind="attrs"
                v-on="on"
                @click="menu.duplicate = true"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ icons.copy }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-overline">Copiar...</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>{{ icons.right }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <div
              class="ad-duplicate-list white overflow-y-auto pa-4"
            >
              <media-player
                v-for="thumb in copyableList"
                :key="`ad-thumb-${thumb.id}`"
                :url="thumb.media.url"
                :title="thumb.title"
                :pause-on-hover="false"
                rounded
                class="my-2"
                @click="create(thumb)"
              />
            </div>
          </v-menu>
        </v-list>
      </v-menu>

      <v-hover
        v-for="link in list"
        :key="`ad-link-${link.id}`"
        v-slot="{ hover }"
      >
        <a 
          :class="[setAdColor(link)+'--text']"
          class="ad-link px-1 flex-shrink-1"
          @mouseover="setHighlight(link.id, true)"
          @mouseout="setHighlight(null)"
          @click="select(link.id)"
        >
          <v-chip
            label
            :color="setAdColor(link)"
            :class="{ 'active': currentlyActive(link), 'highlight': hover||highlight==link.id||link.id==selected }"
            class="ad-chip"
            @click.stop="select(link.id)"
          />
          <v-icon 
            v-show="link.id==selected"
            class="ad-chip-indicator"
          >
            {{ icons.tip }}
          </v-icon>
        </a>
      </v-hover>
      <v-divider class="ml-2" />
    </div>
    <div
      v-if="hasAds"
      ref="ads-list"
      class="ads-list pa-0 px-5 overflow-x-auto smooth-scroll"
      :class="{ 'active': !minimal || highlight!=null }"
      @wheel.stop
    >
      <div
        class="ad-slider d-flex align-center pr-5"
      >
        <v-hover
          v-for="card in list"
          :key="`ad-card-${card.id}`"
          v-slot="{ hover }"
          class="ma-2"
        >
          <ad-card 
            :id="card.id"
            :active="card.active"
            :title="card.title"
            :media="card.media"
            :loading="status[card.id].loading"
            :refresh="refresh"
            :admin="admin"
            :hover="hover"
            :highlight="highlight==card.id"
            :autoplay="!minimal"
            :activatable="activatable"
            width="288"
            :ref="['ad-card-'+card.id]"
            class="ad-card snap-child mb-4 flex-shrink-0"
            @hover="setHighlight"
            @activate="activate"
            @select="select"
          />
        </v-hover>
        <v-btn
          v-show="!archived"
          text
          small
          color="grey"
          :disabled="loading"
          class="mx-4 mb-4"
          @click="load(true)"
        >
          Ver anúncios inativos
        </v-btn>
        <div style="padding-right: 16px; height: 166px;" />
      </div>
    </div>
    <v-container
      v-else-if="!updated"
      fluid
      class="ads-loading d-flex justify-start"
    >
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        width="288"
        height="146"
        type="image"
        class="skeleton ml-4"
      />
    </v-container>
    <v-container
      v-else-if="!minimal&&updated&&editable"
      fluid
      class="ads-empty d-flex align-center pl-8"
    >
      <div
        class="new-ad outlined rounded mb-4 mr-4 accent--text"
        @click="create(null)"
      >
        <div
          class="d-flex align-center justify-center"
          :style="{ height: '96px' }"
        >
          <v-icon 
            large
            color="accent"
          >
            {{ icons.add }}
          </v-icon>
        </div>
        <v-card-title 
          class="title text-overline text-truncate"
        >
          Criar {{ getDictionary('ad') }}...
        </v-card-title>
      </div>
      <v-btn
        v-show="!archived"
        text
        small
        color="grey"
        :loading="loading"
        class="mx-4 mb-4"
        @click="load(true)"
      >
        Ver anúncios inativos
      </v-btn>
    </v-container>
  </div>
</template>

<style>

.ads {
  /* overflow-y: hidden; */
}

.ads .ad-nav {
  max-width: 100%;
  min-height: 32px;
}
.ads.minimal .ads-list {
  position: absolute;
  will-change: height;
  transition: height .5s ease;
  height: 0;
  width: 100%;
  background: rgba(255, 255, 255, .96) !important;
  box-shadow: 0px 16px 16px 24px rgba(255, 255, 255, 0);
}
.ads.minimal .ads-list.active {
  box-shadow: 0px 16px 16px 24px rgba(255, 255, 255, .96);
  height: 166px;
}

.ads .ad-slider {
  opacity: 0;
  will-change: opacity;
  transition: opacity .5s ease-in-out;
}
.ads .ads-list.active .ad-slider {
  opacity: 1;
}

.ads .ad-nav .ad-link {
  position: relative;
  width: 48px;
}
.ads .ad-nav .ad-chip {
  line-height: 4px;
  height: 5px;
  width: 100%;
  opacity: .25;
  will-change: opacity;
  transition: opacity .15s ease;
}
.ads .ad-nav .ad-chip.active {
  opacity: .6;
  box-shadow: 0 0 16px 0 var(--success);
}
.ads .ad-nav .ad-chip.highlight {
  opacity: 1;
}

.ads .ad-nav .ad-chip-indicator {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  color: inherit;
}

.ad-duplicate-list {
  max-height: 80vh;
}

.ads .ads-list {
  scroll-padding: 16px;
  scroll-margin: 8px;
}

.btn-new-option {
  cursor: pointer;
}
.ads .new-ad {
  width: 288px;
  cursor: pointer;
}
.ads .new-ad .title {
  font-size: .6875rem !important;
  line-height: 1rem;
}

</style>

<script>
  import { mdiPlus, mdiImagePlus, mdiImageMultiple, mdiChevronRight, mdiMenuDown } from '@mdi/js';
  import services from '@/services'
  const moment = require("moment");

  export default {
    props: {
      ads: {
        type: Object,
        default: () => {}
      },
      campaigns: {
        type: Array,
        default: () => []
      },
      status: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: [Number, String],
        default: null
      },
      version: {
        type: Number,
        default: 1
      },
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Boolean,
        default: false
      },
      archived: {
        type: Boolean,
        default: false
      },
      minimal: {
        type: Boolean,
        default: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      activatable: {
        type: Boolean,
        default: true
      },
      user: {
        type: Object,
        default: () => {}
      },
      admin: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: true
      },
    },
    
    data: () => ({
      icons: {
        add: mdiPlus,
        new: mdiImagePlus,
        copy: mdiImageMultiple,
        right: mdiChevronRight,
        tip: mdiMenuDown
      },
      menu: {
        new: false,
        duplicate: false,
      },
      highlight: null,
      over: null,
      controller: null
    }),

    computed: {
      list () {
        return _.orderBy(_.map(this.ads, ad => {
          return { ...ad, status: ad.active==2 || _.has(ad, 'edition') && ad.edition!=null ? 0 : 1, ...(this.version==0&&_.has(ad, 'edition')&&ad.edition!=null ? ad.edition : {}) }
        }), ['status', 'active', 'period.start', 'period.end'], ['asc', 'desc', 'desc', 'desc']);
      },
      copyableList () {
        const ads = { 
          ...this.ads, 
          ..._.reduce(_.cloneDeep(this.campaigns), (ads, campaign) => {
            return { 
              ...ads, 
              ...campaign.ads
            }
          }, {})
        }
        return _.orderBy(_.values(ads), ['period.start', 'period.end'], ['desc', 'desc']);
      },
      hasAds () {
        const count = _.size(this.ads);
        return !count==0 && !(count==1&&_.has(this.ads, 'new'));
      },
    },

    methods: {
      ...services,
      
      setHighlight (id, focus) {
        this.hover = id;
        if (this.controller!=null) clearTimeout(this.controller);
        setTimeout(($, id) => {
          if ($.hover==id) $.highlight = id;
          $.controller = null;
        }, id==null ? 1500 : 250, this, id);
        if (!_.isNil(focus)&&focus) {
          const view = _.has(this.$refs, 'ads-list') ? this.$refs['ads-list'] : null;
          const key = 'ad-card-'+id;
          const el = _.has(this.$refs, key) ? this.$refs[key][0].$el : null;
          if (_.isNil(view)||_.isNil(el)) return;
          const hidden = el.offsetLeft+el.clientWidth>=view.clientWidth+view.scrollLeft ? 'right' : el.offsetLeft<=view.scrollLeft ? 'left' : false;
          if (hidden) {
            setTimeout(($, id, view, el, hidden) => {
              if ($.highlight==id) view.scrollLeft = hidden=='left' ? el.offsetLeft - 24 : (el.offsetLeft+el.clientWidth) - view.clientWidth + 24;
            }, 500, this, id, view, el, hidden);
          }
        }
      },

      currentlyActive (ad) {
        const period = _.has(ad, 'edition')&&ad.edition!=null ? ad.edition.period : ad.period;
        const beforeStart = moment().isBefore(period.start);
        const afterEnd = moment().isAfter(period.end);
        return ad.active==1&&(this.version==1||this.version==0&&ad.status==1)&&!beforeStart&&!afterEnd
      },

      setAdColor (ad) {
        return this.version==0&&ad.status==0 ? 
          'primary' : 
          ad.active==1&&(this.version==1||this.version==0&&ad.status==1)&&this.currentlyActive(ad) ? 'success' : 
          'grey';
      },

      load (archived) {
        this.$emit('load', archived);
      },

      select (id) {
        this.highlight = null;
        this.$emit('select', id);
      },

      activate (id) {
        this.$emit('activate', id);
      },

      create (ad) {
        this.menu.new = false;
        this.$emit('new', ad);
      },
    },

    components: {
      AdCard: () => import('@/views/campaigns/AdCard'),
      MediaPlayer: () => import('@/components/campaigns/MediaPlayer'),
    }
  }
</script>